import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/homelayout";
import SEO from "../components/seo";

function IndexPage({ pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO title="Contact" image="https://www.leverid.com/thumbnail-new.png" />
      <Container className="contact-above">
        <Row>
          <Col>
            <h1>Let's talk!</h1>
          </Col>
        </Row>
      </Container>
      <Container className="contactpage fullwidth">
        <Container>
          <Row>
            <Col>
              <h2>Levercode Ltd.</h2>
              <h4>Address:</h4>
              <p>A. H. Tammsaare tee 47, Tallinn 11316, Estonia</p>

              <h4>For governments/corporations:</h4>
              <p>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </p>
              <p>
                <a href="tel:+372 65 65 600">+372 65 65 600</a>
              </p>

              <h4>For press:</h4>
              <p>
                <a href="mailto:press@leverid.com">press@leverid.com</a>
              </p>
            </Col>
            <Col>
              <div className="success custom-success">
                <p>Thank you for sending in your contact details!</p>
              </div>
              <br />
              <p>
                At the moment we do not have any vacancies, but we will start
                recruiting soon.
              </p>
              <br />
              <p>We’ll contact you if a suitable job becomes available.</p>
              <br />
              <p>
                We’ll treat the received information as confidential and in
                accordance with the rules of personal data protection.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export default IndexPage;
